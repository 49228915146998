<template>
  <div>

    <!-- Filter Section -->
    <b-card title="Filters" class="mb-0">
      <b-form @submit.prevent>
        <b-row>
          <!-- Date Start -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.reportSalesInvoice.singular.dateStart')"
              label-for="date-start"
            >
              <flat-pickr
                id="date-start"
                :config="dateFormat"
                v-model="form.dateStart"
                class="form-control form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Date End -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.reportSalesInvoice.singular.dateEnd')"
              label-for="date-start"
            >
              <flat-pickr
                id="date-end"
                :config="dateFormat"
                v-model="form.dateEnd"
                class="form-control form-control-sm"
              />
            </b-form-group>
          </b-col>

          <!-- Customer -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.reportSalesInvoice.singular.customer')"
              label-for="customer-id"
            >
              <v-select
                id="customer-id"
                class="select-size-sm"
                label="brand"
                v-model="form.customerId"
                :options="LOV.customers"
                :reduce="field => field.id"
              />
            </b-form-group>
          </b-col>

          <!-- Status -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('apps.reportSalesInvoice.singular.status')"
              label-for="status"
            >
              <v-select
                id="status"
                class="select-size-sm"
                v-model="form.status"
                :options="LOV.status"
                :clearable="false"
                :reduce="field => field.code"
              />
            </b-form-group>
          </b-col>

          <!-- Tags -->
          <b-col cols="12" md="2">
            <b-form-group
              :label="$t('globalSingular.tags')"
              label-for="tags"
            >
              <v-select
                v-model="form.tags"
                id="tags"
                label="name"
                class="select-size-sm"
                taggable
                multiple
                :options="LOV.tags"
                :reduce="field => field.id"
                :create-option="field => ({id: Date.now(), name: field})"
              />
            </b-form-group>
          </b-col>

          <!-- Button Filter -->
          <b-col cols="12" md="2">
            <b-button block variant="primary" size="sm" @click="getReport" class="mt-2 pd-1">
              {{ $t('globalActions.find') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <div class="d-flex justify-content-end mt-1 mb-1">

      <b-button variant="outline-primary" @click="print">
        <feather-icon icon="FileIcon" />
        {{ $t('globalActions.print') }}
      </b-button>

      <b-button-group class="ml-1">
        <b-dropdown right variant="outline-primary">

          <template #button-content>
            <feather-icon icon="DownloadIcon"/>
            {{ $t('globalActions.export') }}
          </template>

          <b-dropdown-item-button @click="exportToPDF">PDF</b-dropdown-item-button>
          <b-dropdown-item-button @click="exportToExcel">Excel</b-dropdown-item-button>

        </b-dropdown>
      </b-button-group>

    </div>

    <!-- Table Container Card -->
    <b-card no-body id="pdfDom">

      <b-card-body class="text-center">
        <b-card-title>{{ companyName }}</b-card-title>
        <b-card-sub-title>
          <div>{{ $t('apps.reportSalesInvoice.singular.salesInvoiceReport') }}</div>
          <div class="mt-sm-1">{{ periodReportText }}</div>
        </b-card-sub-title>
      </b-card-body>

      <b-table
        id="refListTable"
        ref="refListTable"
        no-provider-paging
        no-provider-filtering
        :filter-included-fields="columnToBeFilter"
        :fields="tableColumns"
        :items="itemLists"
        :filter="searchQuery"
        responsive
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :busy="isBusy"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <template #table-busy>
          <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
            <div class="text mb-1">
              <b-spinner class="align-middle"/>
            </div>
            <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
          </div>
        </template>

        <!-- Column: Amount -->
        <template #cell(remark)="{ item }">
        <span class="d-block text-nowrap" :class="{'font-weight-bolder' : item.isTotal}">
          {{ item.remark }}
        </span>
        </template>

        <!-- Column: Amount -->
        <template #cell(amount)="{ item }">
        <span class="d-block text-nowrap" :class="{'font-weight-bolder' : item.isTotal}">
          {{ formatCurrency(item.amount) }}
        </span>
        </template>

        <!-- Column: Tax Amount -->
        <template #cell(tax_amount)="{ item }">
        <span class="d-block text-nowrap" :class="{'font-weight-bolder' : item.isTotal}">
          {{ formatCurrency(item.tax_amount) }}
        </span>
        </template>

        <!-- Column: Total Amount -->
        <template #cell(total_amount)="{ item }">
        <span class="d-block text-nowrap" :class="{'font-weight-bolder' : item.isTotal}">
          {{ formatCurrency(item.total_amount) }}
        </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="{ item }">
          <b-badge pill :variant="item.status === 'Invoiced' ? 'light-success' : 'light-warning'">{{ item.status }}</b-badge>
        </template>

      </b-table>

      <b-card-body>
        <b-card-text>Printed by: {{ user.name }}</b-card-text>
        <b-card-text>Printed date: {{ currentDate }}</b-card-text>
      </b-card-body>

    </b-card>
  </div>
</template>
<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItemButton,
  BForm,
  BFormGroup,
  BRow,
  BSpinner,
  BTable,
  BBadge
} from 'bootstrap-vue'

import { endOfMonth, format, startOfMonth, parse } from 'date-fns'
import { onMounted, ref } from '@vue/composition-api'
import { formatCurrency, unformatNumber } from '@/utils/formatter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import useListTable from '@/comp-functions/useListTable'
import useFormResource from '@/comp-functions/useFormResource'

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItemButton,
    BTable,
    BSpinner,
    BBadge,
    vSelect,
    flatPickr
  },
  computed: {
    companyName () {
      return this.$store.state.user.currentCompany.name
    },
    user () {
      return this.$store.state.user
    }
  },
  setup () {
    const form = ref({
      dateStart: format(startOfMonth(new Date()), 'dd/MM/yyyy'),
      dateEnd: format(endOfMonth(new Date()), 'dd/MM/yyyy'),
      customerId: '',
      status: 'all',
      tags: []
    })

    const currentDate = format(new Date(), 'dd/MM/yyyy')

    const periodReportText = ref()

    // export format
    const formats = ['xlsx', 'csv', 'txt']

    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })

    const LOV = ref({
      customers: [],
      status: [
        { label: 'All', code: 'all' },
        { label: 'Proforma', code: 'proforma' },
        { label: 'Invoiced', code: 'invoiced' }
      ],
      tags: []
    })

    // Table Handlers
    const tableColumns = [
      { key: 'customer', label: 'Customer' },
      { key: 'code', label: 'Code' },
      { key: 'date', label: 'Date' },
      { key: 'due_date', label: 'Due Date' },
      { key: 'remark', label: 'Remark' },
      { key: 'amount', label: 'Amount' },
      { key: 'tax_amount', label: 'Tax Amount' },
      { key: 'total_amount', label: 'Total Amount' },
      { key: 'status', label: 'Status' }
    ]

    // Table Handlers
    const columnToBeFilter = [
      'code',
      'customer'
    ]

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    } = useListTable({ url: false })

    const { get } = useFormResource({ url: '' })

    const getCustomers = async () => {
      LOV.value.customers = await get({ url: 'master/contact?type={CTCS}'})
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const updatePeriodText = () => {
      const dateStart = parse(form.value.dateStart, 'dd/MM/yyyy', new Date())
      const dateEnd = parse(form.value.dateEnd, 'dd/MM/yyyy', new Date())
      const formatStartDate = format(dateStart, 'd MMMM yyyy')
      const formatEndDate = format(dateEnd, 'd MMMM yyyy')
      periodReportText.value = `${formatStartDate} to ${formatEndDate}`
    }

    onMounted(async () => {
      await getCustomers()
      await getTags()
      const params = [
        `dateStart=${form.value.dateStart}`,
        `dateEnd=${form.value.dateEnd}`,
        `customerId=${form.value.customerId ?? ''}`,
        `tags={${form.value.tags}}`,
        `status=${form.value.status}`
      ]
      const url = `reports/sales/invoice?${ params.join('&') }`
      await fetchLists(url)
      updatePeriodText()
    })

    return {
      form,
      formats,
      LOV,
      dateFormat,
      periodReportText,
      tableColumns,
      columnToBeFilter,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      updatePeriodText,
      formatCurrency,
      currentDate
    }
  },
  methods: {
    getReport () {
      const params = [
        `dateStart=${this.form.dateStart}`,
        `dateEnd=${this.form.dateEnd}`,
        `customerId=${this.form.customerId ?? ''}`,
        `tags={${this.form.tags}}`,
        `status=${this.form.status}`
      ]
      const url = `reports/sales/invoice?${ params.join('&') }`
      this.fetchLists(url)
      this.updatePeriodText()
    },
    print () {
      window.print()
    },
    exportToPDF () {
      this.$nextTick(() => this.getPdf())
    },
    exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.itemLists

        // add title
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list).map((row) => {
          return row.map((cell) => {
            return unformatNumber(cell);
          })
        });
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', '', '', ''],
            [this.$t('apps.reportSalesInvoice.singular.salesInvoiceReport'), '', '', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', '', '', ''],
            ['', '', '', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 8, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 8, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 8, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 8, r: 3 } }
          ],
          filename: `${this.$t('apps.reportSalesInvoice.singular.salesInvoiceReport')} ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .dropdown-menu .dropdown-item {
    width: 100%;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
        margin: 0 !important;
      }

      #pdfDom,
      #pdfDom * {
        visibility: visible;
      }

      #pdfDom {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        margin-top: 100px;
      }

      #pdfDom td, #pdfDom th {
        padding: 0.72rem 1rem;;
      }
    }
  }

  @page {
    size: auto;
  }
</style>
